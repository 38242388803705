
import { FormPage } from '@/mixins'
import { CreateEventModel } from 'tradingmate-modules/src/models/api/events'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import { InputDateTime, InputText, InputTextarea } from '@/components/inputs'
import ContentHtmlInput from '@/components/inputs/ContentHtmlInput.vue'

@Component({
  components: {
    ContentHtmlInput,
    InputText,
    InputDateTime,
    InputTextarea
  }
})
export default class EventCreate extends Mixins(FormPage) {
  private validationErrors = false;

  private branchId = this.$store.state.identity.BusinessBranch.BusinessBranchId

  private createEventModel: CreateEventModel = {
    EventStartDateTime: ((new Date()).toISOString()),
    EventEndDateTime: undefined,
    Name: '',
    Body: '',
    Location: '',
    ExternalLink: ''
  }

  savePost () {
    this.loading = true

    Services.API.Events.CreateEvent(this.branchId, this.createEventModel)
      .then((event) => {
        this.httpError = null
        this.$router.replace(`/event/${event.EventId}`)
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.loading = false
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      })
  }
}
